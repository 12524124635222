import PropTypes from 'prop-types';
import React from 'react';
import Container from '@src/components/ui/container/container';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import './page-header.scss';

const PageHeader = ({
  introduction,
  videoUrl,
  videoPoster,
}) => (
  <header id="PageHeader" className="PageHeader">
    <div className="PageHeader__video-bg">
      <video
        src={videoUrl}
        autoPlay
        playsInline
        loop
        muted
        poster={videoPoster}
      />
    </div>
    <Container classNames="PageHeader__container">
      <div className="PageHeader__introduction">
        <ChrMarkdown>
          {introduction}
        </ChrMarkdown>
      </div>
    </Container>
    <div className="scrolldown">
      <svg width="20" height="34" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="18" height="32" rx="9" stroke="white" strokeWidth="2" />
        <circle className="scrolldown-p1" cx="10" cy="9" r="2" fill="white" />
        <circle className="scrolldown-p2" cx="10" cy="9" r="2" fill="white" />
      </svg>
    </div>
  </header>
);

PageHeader.propTypes = {
  introduction: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  videoPoster: PropTypes.string.isRequired,
};

export default PageHeader;
