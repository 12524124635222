import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import * as R from 'ramda';
import Layout from '@src/components/layout';
import SEO from '@src/components/seo';
import PageHeader from '@src/components/ui/page-header/page-header';
import PageNav from '@src/components/ui/page-nav/page-nav';
import HeaderVideo from '@src/videos/header-loop.mp4';
import HeaderVideoPoster from '@src/images/header/header-poster.jpg';
import IntroductionSection from '@src/components/sections/introduction/introduction';
import FeatureImageSection from '@src/components/sections/feature-image/feature-image';
import ExploreSection from '@src/components/sections/explore/explore';
import WorkByNumbersSection from '@src/components/sections/looking-back/work-by-numbers/work-by-numbers';
import WorkInLivesSection from '@src/components/sections/looking-back/work-in-lives/work-in-lives';
import KeyIssuesSection from '@src/components/sections/looking-forward/key-issues/key-issues';
import PlacesToWatchSection from '@src/components/sections/looking-forward/places-to-watch/places-to-watch';
import OnTheGroundSection from '@src/components/sections/on-the-ground/on-the-ground';
import OurCommitmentSection from '@src/components/sections/our-commitment/our-commitment';
import WhatNextSection from '@src/components/sections/what-next/what-next';
import PageFooter from '@src/components/ui/page-footer/page-footer';
import './index.scss';

const IndexPage = ({
  data: {
    common,
    header,
    navigation,
    introduction,
    featureImages,
    explore,
    exploreCountries,
    countryIssues,
    workByNumbers,
    mainStats,
    localStats,
    workInLives,
    stories,
    keyIssues,
    issues,
    placesToWatch,
    places,
    onTheGround,
    videos,
    ourCommitment,
    whatNext,
    pageFooter,
  },
}) => (
  <Layout
    navigation={(
      <PageNav
        pageSections={[
          {
            id: 'explore',
            label: navigation.frontmatter.issuesLabel,
          },
          {
            id: 'work-by-numbers',
            label: navigation.frontmatter.lookingBackLabel,
          },
          {
            id: 'key-issues',
            label: navigation.frontmatter.lookingForwardLabel,
          },
          {
            id: 'on-the-ground',
            label: navigation.frontmatter.onTheGroundLabel,
          },
          {
            id: 'our-commitment',
            label: navigation.frontmatter.ourCommitmentLabel,
          },
        ]}
      />
    )}
    header={(
      <PageHeader
        introduction={header.introduction}
        videoUrl={HeaderVideo}
        videoPoster={HeaderVideoPoster}
      />
    )}
    footer={(
      <PageFooter
        address={pageFooter.frontmatter.address}
        phone={pageFooter.frontmatter.phone}
        copyright={pageFooter.frontmatter.copyright}
      />
    )}
  >
    <SEO
      title={common.frontmatter.pageTitle}
      description={common.frontmatter.pageDescription}
    />

    <IntroductionSection introduction={introduction} />

    <FeatureImageSection
      id="feature-image-first"
      url={featureImages.frontmatter.firstUrl.childImageSharp.fluid}
      alt={featureImages.frontmatter.firstAlt}
      caption={featureImages.frontmatter.firstCaption}
      photographer={featureImages.frontmatter.firstPhotographer}
    />

    <ExploreSection
      heading={explore.frontmatter.heading}
      mapMessage={explore.frontmatter.mapMessage}
      countries={exploreCountries.nodes}
      countryIssues={countryIssues.nodes}
    />

    <WorkByNumbersSection
      heading={workByNumbers.frontmatter.heading}
      introduction={workByNumbers.frontmatter.introduction}
      mainStats={R.sort((a, b) => a.frontmatter.order - b.frontmatter.order, mainStats.nodes)}
      localStats={R.sort((a, b) => a.frontmatter.order - b.frontmatter.order, localStats.nodes)}
      bridgingStatement={workByNumbers.frontmatter.bridgingStatement}
    />

    <WorkInLivesSection
      heading={workInLives.frontmatter.heading}
      introduction={workInLives.frontmatter.introduction}
      stories={R.sort((a, b) => a.frontmatter.order - b.frontmatter.order, stories.nodes)}
    />

    <KeyIssuesSection
      heading={keyIssues.frontmatter.heading}
      introduction={keyIssues.frontmatter.introduction}
      issues={R.sort((a, b) => a.frontmatter.order - b.frontmatter.order, issues.nodes)}
    />

    <PlacesToWatchSection
      heading={placesToWatch.frontmatter.heading}
      introduction={placesToWatch.frontmatter.introduction}
      places={R.sort((a, b) => a.frontmatter.order - b.frontmatter.order, places.nodes)}
    />

    <OnTheGroundSection
      heading={onTheGround.frontmatter.heading}
      introduction={onTheGround.frontmatter.introduction}
      videos={R.sort((a, b) => a.frontmatter.order - b.frontmatter.order, videos.nodes)}
    />

    <OurCommitmentSection
      heading={ourCommitment.frontmatter.heading}
      content={ourCommitment.frontmatter.introduction}
    />

    <WhatNextSection
      heading={whatNext.frontmatter.heading}
      callsToAction={[
        {
          title: whatNext.frontmatter.ctaOneTitle,
          content: whatNext.frontmatter.ctaOneContent,
          type: whatNext.frontmatter.ctaOneType,
          label: whatNext.frontmatter.ctaOneLabel,
          url: whatNext.frontmatter.ctaOneUrl,
        },
        {
          title: whatNext.frontmatter.ctaTwoTitle,
          content: whatNext.frontmatter.ctaTwoContent,
          type: whatNext.frontmatter.ctaTwoType,
          label: whatNext.frontmatter.ctaTwoLabel,
          url: whatNext.frontmatter.ctaTwoUrl,
        },
        {
          title: whatNext.frontmatter.ctaThreeTitle,
          content: whatNext.frontmatter.ctaThreeContent,
          type: whatNext.frontmatter.ctaThreeType,
          label: whatNext.frontmatter.ctaThreeLabel,
          url: whatNext.frontmatter.ctaThreeUrl,
        },
      ]}
    />

    <FeatureImageSection
      id="feature-image-last"
      url={featureImages.frontmatter.lastUrl.childImageSharp.fluid}
      alt={featureImages.frontmatter.lastAlt}
    />
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    common: PropTypes.shape({
      frontmatter: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        pageDescription: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    header: PropTypes.shape({
      introduction: PropTypes.string.isRequired,
    }).isRequired,
    navigation: PropTypes.shape({
      frontmatter: PropTypes.shape({
        introductionLabel: PropTypes.string.isRequired,
        issuesLabel: PropTypes.string.isRequired,
        lookingBackLabel: PropTypes.string.isRequired,
        lookingForwardLabel: PropTypes.string.isRequired,
        onTheGroundLabel: PropTypes.string.isRequired,
        ourCommitmentLabel: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    introduction: PropTypes.shape({
      frontmatter: PropTypes.shape({
        startingContent: PropTypes.string.isRequired,
        surviveTitle: PropTypes.string.isRequired,
        surviveDescription: PropTypes.string.isRequired,
        recoverTitle: PropTypes.string.isRequired,
        recoverDescription: PropTypes.string.isRequired,
        buildAFutureTitle: PropTypes.string.isRequired,
        buildAFutureDescription: PropTypes.string.isRequired,
        endingContent: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    featureImages: PropTypes.shape({
      frontmatter: PropTypes.shape({
        firstUrl: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}).isRequired,
          }).isRequired,
        }).isRequired,
        firstAlt: PropTypes.string.isRequired,
        firstCaption: PropTypes.string.isRequired,
        firstPhotographer: PropTypes.string.isRequired,
        lastUrl: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}).isRequired,
          }).isRequired,
        }).isRequired,
        lastAlt: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    explore: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        mapMessage: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    exploreCountries: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        frontmatter: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          introduction: PropTypes.string.isRequired,
          issues: PropTypes.string.isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    countryIssues: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        frontmatter: PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          icon: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fixed: PropTypes.shape({}).isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    workByNumbers: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        introduction: PropTypes.string.isRequired,
        bridgingStatement: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    mainStats: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        frontmatter: PropTypes.shape({
          id: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired,
          stat: PropTypes.number.isRequired,
          heroImage: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({}).isRequired,
            }).isRequired,
          }).isRequired,
          imageAlt: PropTypes.string.isRequired,
        }).isRequired,
        content: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    localStats: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        frontmatter: PropTypes.shape({
          id: PropTypes.string.isRequired,
          country: PropTypes.string.isRequired,
          statistic: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    workInLives: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        introduction: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    stories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        frontmatter: PropTypes.shape({
          id: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          category: PropTypes.string.isRequired,
          excerpt: PropTypes.string.isRequired,
          heroImage: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({}).isRequired,
            }).isRequired,
          }).isRequired,
          imageAlt: PropTypes.string.isRequired,
        }).isRequired,
        content: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    keyIssues: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        introduction: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    issues: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        frontmatter: PropTypes.shape({
          id: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          chartTitle: PropTypes.string.isRequired,
          chartCitation: PropTypes.string.isRequired,
          chartUrl: PropTypes.string.isRequired,
          chartReferenceUrl: PropTypes.string.isRequired,
        }).isRequired,
        overview: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    placesToWatch: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        introduction: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    places: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        frontmatter: PropTypes.shape({
          id: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          heroImage: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({}).isRequired,
            }).isRequired,
          }).isRequired,
          imageAlt: PropTypes.string.isRequired,
        }).isRequired,
        overview: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    onTheGround: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        introduction: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    videos: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        frontmatter: PropTypes.shape({
          id: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          heroImage: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({}).isRequired,
            }).isRequired,
          }).isRequired,
          imageAlt: PropTypes.string.isRequired,
          videoUrl: PropTypes.string.isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    ourCommitment: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        introduction: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    whatNext: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        ctaOneTitle: PropTypes.string.isRequired,
        ctaOneContent: PropTypes.string.isRequired,
        ctaOneType: PropTypes.string.isRequired,
        ctaOneLabel: PropTypes.string.isRequired,
        ctaOneUrl: PropTypes.string,
        ctaTwoTitle: PropTypes.string.isRequired,
        ctaTwoContent: PropTypes.string.isRequired,
        ctaTwoType: PropTypes.string.isRequired,
        ctaTwoLabel: PropTypes.string.isRequired,
        ctaTwoUrl: PropTypes.string,
        ctaThreeTitle: PropTypes.string.isRequired,
        ctaThreeContent: PropTypes.string.isRequired,
        ctaThreeType: PropTypes.string.isRequired,
        ctaThreeLabel: PropTypes.string.isRequired,
        ctaThreeUrl: PropTypes.string,
      }).isRequired,
    }).isRequired,
    pageFooter: PropTypes.shape({
      frontmatter: PropTypes.shape({
        address: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        copyright: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const queries = graphql`
  query HomeQuery {
    common: markdownRemark(fileAbsolutePath: {regex: "/common.md/"}) {
      frontmatter {
        pageTitle
        pageDescription
      }
    }
    navigation: markdownRemark(fileAbsolutePath: {regex: "/navigation.md/"}) {
      frontmatter {
        introductionLabel
        issuesLabel
        lookingBackLabel
        lookingForwardLabel
        onTheGroundLabel
        ourCommitmentLabel
      }
    }
    header: markdownRemark(fileAbsolutePath: {regex: "/page-header.md/"}) {
      introduction:rawMarkdownBody
    }
    introduction: markdownRemark(fileAbsolutePath: {regex: "/introduction.md/"}) {
      frontmatter {
        startingContent
        surviveTitle
        surviveDescription
        recoverTitle
        recoverDescription
        buildAFutureTitle
        buildAFutureDescription
        endingContent
      }
    }
    featureImages: markdownRemark(fileAbsolutePath: {regex: "/feature-images.md/"}) {
      frontmatter {
        firstUrl {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        firstAlt
        firstCaption
        firstPhotographer
        lastUrl {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        lastAlt
      }
    }
    explore: markdownRemark(fileAbsolutePath: {regex: "/explore.md/"}) {
      frontmatter {
        heading
        mapMessage
      }
    }
    exploreCountries: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/explore\//"}}, sort: {fields: frontmatter___name, order: ASC}) {
      nodes {
        frontmatter {
          id
          name
          introduction
          issues
        }
      }
    }
    countryIssues: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/country-issues\//"}}, sort: {fields: frontmatter___title, order: ASC}) {
      nodes {
        frontmatter {
          id
          title
          description
          icon {
            childImageSharp {
              fixed(width: 94) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    workByNumbers: markdownRemark(fileAbsolutePath: {regex: "/looking-back\/work-by-numbers.md/"}) {
      frontmatter {
        heading
        introduction
        bridgingStatement
      }
    }
    mainStats: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/looking-back\/work-by-numbers\/main\/[a-zA-Z\\-]*.md/"}}, sort: {fields: frontmatter___order, order: ASC}) {
      nodes {
        frontmatter {
          id
          order
          stat
          heroImage {
            childImageSharp {
              fluid(maxWidth: 1160) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
        }
        content:rawMarkdownBody
      }
    }
    localStats: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/looking-back\/work-by-numbers\/local\/[a-zA-Z0-9\\-]*.md/"}}, sort: {fields: frontmatter___order, order: ASC}) {
      nodes {
        frontmatter {
          id
          country
          statistic
          description
        }
      }
    }
    workInLives: markdownRemark(fileAbsolutePath: {regex: "/looking-back\/work-in-lives.md/"}) {
      frontmatter {
        heading
        introduction
      }
    }
    stories: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/looking-back\/work-in-lives\/stories/"}}, sort: {fields: frontmatter___order, order: ASC}) {
      nodes {
        frontmatter {
          id
          order
          title
          category
          excerpt
          heroImage {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
        }
        content:rawMarkdownBody
      }
    }
    keyIssues: markdownRemark(fileAbsolutePath: {regex: "/looking-forward\/key-issues.md/"}) {
      frontmatter {
        heading
        introduction
      }
    }
    issues: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/looking-forward\/key-issues\/[a-zA-Z\\-]*.md/"}}, sort: {fields: frontmatter___order, order: ASC}) {
      nodes {
        frontmatter {
          id
          order
          title
          chartTitle
          chartCitation
          chartUrl
          chartReferenceUrl
        }
        overview:rawMarkdownBody
      }
    }
    placesToWatch: markdownRemark(fileAbsolutePath: {regex: "/looking-forward\/places-to-watch.md/"}) {
      frontmatter {
        heading
        introduction
      }
    }
    places: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/looking-forward\/places-to-watch\/[0-9a-zA-Z\\-]*.md/"}}, sort: {fields: frontmatter___order, order: ASC}) {
      nodes {
        frontmatter {
          id
          order
          title
          heroImage {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
        }
        overview:rawMarkdownBody
      }
    }
    onTheGround: markdownRemark(fileAbsolutePath: {regex: "/on-the-ground\/on-the-ground.md/"}) {
      frontmatter {
        heading
        introduction
      }
    }
    videos: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/on-the-ground\/videos\/[0-9a-zA-Z\\-]*.md/"}}, sort: {fields: frontmatter___order, order: ASC}) {
      nodes {
        frontmatter {
          id
          order
          title
          excerpt
          heroImage {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
          videoUrl
        }
      }
    }
    ourCommitment: markdownRemark(fileAbsolutePath: {regex: "/our-commitment.md/"}) {
      frontmatter {
        heading
        introduction
      }
    }
    whatNext: markdownRemark(fileAbsolutePath: {regex: "/what-next.md/"}) {
      frontmatter {
        heading
        ctaOneTitle
        ctaOneContent
        ctaOneType
        ctaOneLabel
        ctaOneUrl
        ctaTwoTitle
        ctaTwoContent
        ctaTwoType
        ctaTwoLabel
        ctaTwoUrl
        ctaThreeTitle
        ctaThreeContent
        ctaThreeType
        ctaThreeLabel
        ctaThreeUrl
      }
    }
    pageFooter: markdownRemark(fileAbsolutePath: {regex: "/page-footer.md/"}) {
      frontmatter {
        address
        phone
        copyright
      }
    }
  }
`;

export default IndexPage;
