import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import './place.scss';

const Place = ({
  id,
  title,
  heroImage,
  imageAlt,
  overview,
}) => (
  <div className="Place" id={id}>
    <Img fluid={heroImage} alt={imageAlt} />
    <div className="Place__details">
      <h3>{ title }</h3>
      <ChrMarkdown>
        { overview }
      </ChrMarkdown>
    </div>
  </div>
);

Place.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({}).isRequired,
  imageAlt: PropTypes.string.isRequired,
  overview: PropTypes.string.isRequired,
};

export default Place;
