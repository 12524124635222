import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import * as R from 'ramda';

const GoogleTagManager = ({ gtmId, siteHasCookieHub }) => {
  if (R.isNil(gtmId)
    || R.isEmpty(gtmId)) {
    return (<></>);
  }

  const renderCookieHubGtm = () => (
    `
      function injectGTM() {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      }
      if (false) { 
        injectGTM();
      } else {
        document.addEventListener('cookie_consent', function (e) {
          injectGTM();
        }, false);
      }      
      document.addEventListener('cookiehub', function (e) {
        if (e.detail.allowed && e.detail.type === 'analytics') {
          injectGTM();
        }
      });  
    `
  );
  const renderStandardGtm = () => (
    `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `
  );

  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML: (siteHasCookieHub
            ? renderCookieHubGtm()
            : renderStandardGtm()),
        },
      ]}
    />
  );
};

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string,
  siteHasCookieHub: PropTypes.bool,
};

GoogleTagManager.defaultProps = {
  gtmId: null,
  siteHasCookieHub: false,
};

export default GoogleTagManager;
