/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import './modal.scss';

const Modal = ({
  show, header, children, footer, onClose, classNames,
}) => {
  const queries = useStaticQuery(graphql`
    {
      common: markdownRemark(fileAbsolutePath: {regex: "/common.md/"}) {
        frontmatter {
          close
        }
      }
    }`);

  useEffect(() => {
    const closeEvent = (ev) => { if (ev.keyCode === 27) { onClose(); } };
    if (show) { window.addEventListener('keydown', closeEvent); }
    return (() => {
      window.removeEventListener('keydown', closeEvent);
    });
  }, [show]);

  return (
    <>
      {/* {show && <Helmet bodyAttributes={{ class: 'locked' }} /> } */}
      <div className={`modal${show ? ' active' : ''}${classNames ? ` ${classNames}` : ''}`}>
        <div
          className="backdrop"
          onClick={(ev) => { ev.stopPropagation(); onClose(); }}
          onKeyDown={(ev) => {
            if (ev.keyCode === 27) {
              onClose();
            }
          }}
        />
        <div className="modal-container">
          <button className="close" type="button" onClick={onClose}>{queries.common.frontmatter.close}</button>
          { header && <header>{header}</header> }
          {children}
          { footer && <footer>{footer}</footer> }
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  classNames: PropTypes.string,
};

Modal.defaultProps = {
  show: false,
  header: null,
  footer: null,
  children: null,
  classNames: '',
};

export default Modal;
