import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Container from '@src/components/ui/container/container';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import './key-issues.scss';

const KeyIssuesSection = ({
  heading,
  introduction,
  issues,
}) => {
  const [activeIssue, setActiveIssue] = useState(issues[0]);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!R.isNil(activeIssue) && window.matchMedia('(max-width: 699px)').matches) {
      const offset = document.getElementById('page-nav').clientHeight + 20;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = document.getElementById(`key_issue__${activeIssue.frontmatter.id}`).getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [activeIssue]);

  return (
    <section id="key-issues" className="LookingForward KeyIssues">
      <Container>
        <header>
          <ChrMarkdown>
            { heading }
          </ChrMarkdown>
          <ChrMarkdown>
            { introduction }
          </ChrMarkdown>
        </header>

        <div className="KeyIssues__issues">
          <ul className="issues-list">
            { R.map((issue) => (
              <li
                className={!R.isNil(activeIssue) && activeIssue.frontmatter.id === issue.frontmatter.id ? 'selected' : ''}
                key={`key_issue__${issue.frontmatter.id}`}
                id={`key_issue__${issue.frontmatter.id}`}
              >
                <div className="title">
                  <button
                    type="button"
                    className="toggle-issue"
                    onClick={() => {
                      setActiveIssue(
                        !R.isNil(activeIssue) && activeIssue.frontmatter.id === issue.frontmatter.id
                          ? null
                          : issue,
                      );
                    }}
                  >
                    { issue.frontmatter.title }
                  </button>
                </div>
                { !R.isNil(activeIssue) && activeIssue.frontmatter.id === issue.frontmatter.id && (
                  <div className="details">
                    <iframe
                      width="500"
                      height="400"
                      scrolling="no"
                      title={issue.frontmatter.chartTitle}
                      src={issue.frontmatter.chartUrl}
                    />
                    <div>
                      <div className="chart-details">
                        <h4>{activeIssue.frontmatter.chartTitle}</h4>
                        <ChrMarkdown>
                          { activeIssue.frontmatter.chartCitation }
                        </ChrMarkdown>
                      </div>
                      <div className="overview">
                        <ChrMarkdown>
                          { activeIssue.overview }
                        </ChrMarkdown>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ), issues)}
          </ul>
          <div className="selected-issue">
            { !R.isNil(activeIssue) && (
              <>
                <iframe
                  width="500"
                  height="400"
                  scrolling="no"
                  title={activeIssue.frontmatter.chartTitle}
                  src={activeIssue.frontmatter.chartUrl}
                />
                <div>
                  <div className="chart-details">
                    <h4>
                      <a href={activeIssue.frontmatter.chartReferenceUrl}>
                        {activeIssue.frontmatter.chartTitle}
                      </a>
                    </h4>
                    <ChrMarkdown>
                      { activeIssue.frontmatter.chartCitation }
                    </ChrMarkdown>
                  </div>
                  <div className="overview">
                    <ChrMarkdown>
                      { activeIssue.overview }
                    </ChrMarkdown>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

KeyIssuesSection.propTypes = {
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        chartTitle: PropTypes.string.isRequired,
        chartCitation: PropTypes.string.isRequired,
        chartUrl: PropTypes.string.isRequired,
        chartReferenceUrl: PropTypes.string.isRequired,
      }).isRequired,
      overview: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default KeyIssuesSection;
