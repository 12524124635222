import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const MenuItem = ({
  id,
  label,
  active,
  afterClick,
  classNames,
}) => {
  const [anchorTarget, setAnchorTarget] = useState(null);

  useEffect(() => {
    setAnchorTarget(document.getElementById(id));
  }, [id]);

  const handleClick = (event) => {
    event.preventDefault();
    afterClick(); // run the after click event - before the scroll

    const offset = 0;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = anchorTarget.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <li className={classNames}>
      <a
        href={`#${id}`}
        onClick={handleClick}
        className={active ? 'active' : ''}
        aria-label={`Scroll to ${label}`}
      >
        {label}
      </a>
    </li>
  );
};

MenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  afterClick: PropTypes.func,
  classNames: PropTypes.string,
};

MenuItem.defaultProps = {
  active: false,
  afterClick: () => {}, // method to do nothing
  classNames: '',
};

export default MenuItem;
