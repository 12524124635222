import React from 'react';
import PropTypes from 'prop-types';
import Container from '@src/components/ui/container/container';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import './introduction.scss';

const IntroductionSection = ({
  introduction,
}) => (
  <section id="introduction" className="Introduction">
    <Container>
      <ChrMarkdown>
        { introduction.frontmatter.startingContent }
      </ChrMarkdown>

      <ul className="Introduction__value-prop">
        <li>
          <h4>{ introduction.frontmatter.surviveTitle }</h4>
          <ChrMarkdown>
            { introduction.frontmatter.surviveDescription }
          </ChrMarkdown>
        </li>
        <li>
          <h4>{ introduction.frontmatter.recoverTitle }</h4>
          <ChrMarkdown>
            { introduction.frontmatter.recoverDescription }
          </ChrMarkdown>
        </li>
        <li>
          <h4>{ introduction.frontmatter.buildAFutureTitle }</h4>
          <ChrMarkdown>
            { introduction.frontmatter.buildAFutureDescription }
          </ChrMarkdown>
        </li>
      </ul>
      {/* <ChrMarkdown>
        { introduction.frontmatter.endingContent }
      </ChrMarkdown> */}
    </Container>
  </section>
);

IntroductionSection.propTypes = {
  introduction: PropTypes.shape({
    frontmatter: PropTypes.shape({
      startingContent: PropTypes.string.isRequired,
      surviveTitle: PropTypes.string.isRequired,
      surviveDescription: PropTypes.string.isRequired,
      recoverTitle: PropTypes.string.isRequired,
      recoverDescription: PropTypes.string.isRequired,
      buildAFutureTitle: PropTypes.string.isRequired,
      buildAFutureDescription: PropTypes.string.isRequired,
      endingContent: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default IntroductionSection;
