import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { SocialIcon } from 'react-social-icons';
import './social-icons.scss';

const SocialIcons = ({ theme }) => {
  const facebookUrl = R.pathOr('', [], process.env.GATSBY_FACEBOOK_URL);
  const twitterUrl = R.pathOr('', [], process.env.GATSBY_TWITTER_URL);
  const youtubeUrl = R.pathOr('', [], process.env.GATSBY_YOUTUBE_URL);
  const instagramUrl = R.pathOr('', [], process.env.GATSBY_INSTAGRAM_URL);
  const linkedinUrl = R.pathOr('', [], process.env.GATSBY_LINKEDIN_URL);

  return (
    <ul className={`social-icons ${theme}`}>
      { !R.isNil(facebookUrl) && <li><SocialIcon url={facebookUrl} /></li> }
      { !R.isNil(twitterUrl) && <li><SocialIcon url={twitterUrl} /></li> }
      { !R.isNil(youtubeUrl) && <li><SocialIcon url={youtubeUrl} /></li> }
      { !R.isNil(instagramUrl) && <li><SocialIcon url={instagramUrl} /></li> }
      { !R.isNil(linkedinUrl) && <li><SocialIcon url={linkedinUrl} /></li> }
    </ul>
  );
};

SocialIcons.propTypes = {
  theme: PropTypes.string,
};

SocialIcons.defaultProps = {
  theme: 'dark',
};

export default SocialIcons;
