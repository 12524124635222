import React from 'react';
import PropTypes from 'prop-types';
import './container.scss';

const Container = ({
  children,
  classNames,
}) => (
  <div className={`Container${classNames ? ` ${classNames}` : ''}`}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string,
};

Container.defaultProps = {
  classNames: '',
};

export default Container;
