import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import Modal from '@src/components/ui/modal/modal';
import './video.scss';

const Video = ({
  id,
  title,
  excerpt,
  heroImage,
  imageAlt,
  videoUrl,
}) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="Video">
      <button
        type="button"
        className="play-icon"
        onClick={() => { setShowVideo(true); }}
      >
        Watch now
      </button>
      <Img fluid={heroImage} alt={imageAlt} />
      <div className="Video__details">
        <h4>{ title }</h4>
        <ChrMarkdown>
          { excerpt }
        </ChrMarkdown>
        <button
          type="button"
          onClick={() => { setShowVideo(true); }}
        >
          Watch now
        </button>
      </div>

      <Modal
        show={showVideo}
        classNames="Video__modal"
        onClose={() => { setShowVideo(false); }}
      >
        {showVideo && (
          <iframe
            title={title}
            width="560"
            height="315"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </Modal>
    </div>
  );
};

Video.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({}).isRequired,
  imageAlt: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

export default Video;
