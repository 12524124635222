import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import * as R from 'ramda';

const HotJar = ({ hotJarId, hotJarSv, siteHasCookieHub }) => {
  if (R.isNil(hotJarId) || R.isEmpty(hotJarId)
    || R.isNil(hotJarSv) || R.isEmpty(hotJarSv)) {
    return (<></>);
  }

  const renderCookieHubHotJar = () => (
    `
      function injectHotJar() {
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${hotJarId},hjsv:${hotJarSv}};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
      }
      if (false) { 
        injectHotJar();
      } else {
        document.addEventListener('cookie_consent', function (e) {
          injectHotJar();
        }, false);
      }      
      document.addEventListener('cookiehub', function (e) {
        if (e.detail.allowed && e.detail.type === 'analytics') {
          injectHotJar();
        }
      });  
    `
  );
  const renderStandardHotJar = () => (
    `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${hotJarId},hjsv:${hotJarSv}};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
    `
  );

  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML: (siteHasCookieHub
            ? renderCookieHubHotJar()
            : renderStandardHotJar()),
        },
      ]}
    />
  );
};

HotJar.propTypes = {
  hotJarId: PropTypes.string,
  hotJarSv: PropTypes.string,
  siteHasCookieHub: PropTypes.bool,
};

HotJar.defaultProps = {
  hotJarId: null,
  hotJarSv: null,
  siteHasCookieHub: false,
};

export default HotJar;



              
        