import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import * as R from 'ramda';
import Container from '@src/components/ui/container/container';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import ShareIcons from '@src/components/ui/share-icons/share-icons';
import './what-next.scss';

const WhatNextSection = ({
  heading,
  callsToAction,
}) => (
  <section id="what-next" className="WhatNext">
    <Container>
      <header>
        <ChrMarkdown>
          { heading }
        </ChrMarkdown>
      </header>

      <ul className="WhatNext__cta">
        { R.map((cta) => (
          <li key={uuid()}>
            <h4>{ cta.title }</h4>
            <ChrMarkdown>
              { cta.content }
            </ChrMarkdown>
            { cta.type === 'social-share' && (
              <ShareIcons
                shareTitle={cta.label.split('||')[0]}
                shareEmailMessage={cta.label.split('||')[1]}
              />
            )}
            { cta.type === 'button' && (
              <button
                type="button"
                onClick={() => { document.location = cta.url; }}
              >
                { cta.label }
              </button>
            )}
          </li>
        ), callsToAction)}
      </ul>
    </Container>
  </section>
);

WhatNextSection.propTypes = {
  heading: PropTypes.string.isRequired,
  callsToAction: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
  })).isRequired,
};

export default WhatNextSection;
