import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import Story from './story';
import './work-in-lives.scss';

const WorkInLivesSection = ({
  heading,
  introduction,
  stories,
}) => (
  <section id="work-in-lives" className="LookingBack WorkInLives">
    <header>
      <ChrMarkdown>
        { heading }
      </ChrMarkdown>
      <ChrMarkdown>
        { introduction }
      </ChrMarkdown>
    </header>

    <div className="WorkInLives__stories">
      { R.map((story) => (
        <Story
          key={`story--${story.frontmatter.id}`}
          id={`story--${story.frontmatter.id}`}
          heroImage={story.frontmatter.heroImage.childImageSharp.fluid}
          imageAlt={story.frontmatter.imageAlt}
          title={story.frontmatter.title}
          category={story.frontmatter.category}
          excerpt={story.frontmatter.excerpt}
          content={story.content}
        />
      ), stories)}
    </div>
  </section>
);

WorkInLivesSection.propTypes = {
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        excerpt: PropTypes.string.isRequired,
        heroImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}).isRequired,
          }).isRequired,
        }).isRequired,
        imageAlt: PropTypes.string.isRequired,
      }).isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default WorkInLivesSection;
