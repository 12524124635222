/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '@src/components/ui/container/container';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import Place from './place';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import './places-to-watch.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);

const PlacesToWatchSection = ({
  heading,
  introduction,
  places,
}) => (
  <section id="places-to-watch" className="LookingForward PlacesToWatch">
    <Container>
      <header>
        <ChrMarkdown>
          { heading }
        </ChrMarkdown>
        <ChrMarkdown>
          { introduction }
        </ChrMarkdown>
      </header>
      <div className="PlacesToWatch__places">
        <Swiper
          spaceBetween={100}
          slidesPerView={1}
          grabCursor
          navigation
          pagination={{ type: 'fraction', clickable: true }}
        >
          { R.map((place) => (
            <SwiperSlide key={`place--${place.frontmatter.id}`}>
              <Place
                id={`place--${place.frontmatter.id}`}
                heroImage={place.frontmatter.heroImage.childImageSharp.fluid}
                imageAlt={place.frontmatter.imageAlt}
                title={place.frontmatter.title}
                overview={place.overview}
              />
            </SwiperSlide>
          ), places)}
        </Swiper>
      </div>
    </Container>
  </section>
);

PlacesToWatchSection.propTypes = {
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  places: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        heroImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}).isRequired,
          }).isRequired,
        }).isRequired,
        imageAlt: PropTypes.string.isRequired,
      }).isRequired,
      overview: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default PlacesToWatchSection;
