import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import Modal from '@src/components/ui/modal/modal';
import './story.scss';

const Story = ({
  id,
  title,
  category,
  excerpt,
  heroImage,
  imageAlt,
  content,
}) => {
  const [showFullStory, setShowFullStory] = useState(false);

  return (
    <div className="Story">
      <Img fluid={heroImage} alt={imageAlt} />
      <div className="Story__details">
        <h4>{ title }</h4>
        <ul className="categories">
          <li className={`category-${category.replace(/\s/g, '-').toLowerCase()}`}>
            { category }
          </li>
        </ul>
        <ChrMarkdown>
          { excerpt }
        </ChrMarkdown>
        <button
          type="button"
          onClick={() => { setShowFullStory(true); }}
        >
          Read story
        </button>
      </div>

      <Modal
        show={showFullStory}
        classNames="Story__modal"
        onClose={() => { setShowFullStory(false); }}
      >
        { showFullStory && (
          <div className="Story__modal--content-wrapper">
            <div className="Story__modal--hero">
              <Img fluid={heroImage} alt={imageAlt} />
            </div>
            <div className="Story__modal--content">
              <header>
                <h4>{ title }</h4>
                <ul className="categories">
                  <li className={`category-${category.replace(/\s/g, '-').toLowerCase()}`}>
                    { category }
                  </li>
                </ul>
              </header>
              <ChrMarkdown>
                {content}
              </ChrMarkdown>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

Story.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({}).isRequired,
  imageAlt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Story;
