import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Container from '@src/components/ui/container/container';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import MainStat from './main-stat';
import './work-by-numbers.scss';

const WorkByNumbersSection = ({
  heading,
  introduction,
  mainStats,
  localStats,
  bridgingStatement,
}) => (
  <section id="work-by-numbers" className="LookingBack WorkByNumbers">
    <Container classNames="global-stats">
      <header>
        <ChrMarkdown>
          { heading }
        </ChrMarkdown>
        <ChrMarkdown>
          { introduction }
        </ChrMarkdown>
      </header>
      { R.map((stat) => (
        <MainStat
          key={`main-stat--${stat.frontmatter.id}`}
          id={`main-stat--${stat.frontmatter.id}`}
          heroImage={stat.frontmatter.heroImage.childImageSharp.fluid}
          imageAlt={stat.frontmatter.imageAlt}
          stat={stat.frontmatter.stat}
          description={stat.content}
          sequence={stat.frontmatter.order}
        />
      ), mainStats)}
    </Container>
    <Container classNames="bridging-statement">
      <ChrMarkdown>
        { bridgingStatement }
      </ChrMarkdown>
    </Container>
    <Container classNames="local-stats">
      <ul>
        { R.map((stat) => (
          <li key={`local-stat--${stat.frontmatter.id}`}>
            <span className="country">{ stat.frontmatter.country }</span>
            <h4>{ stat.frontmatter.statistic }</h4>
            <ChrMarkdown>
              { stat.frontmatter.description }
            </ChrMarkdown>
          </li>
        ), localStats)}
      </ul>
    </Container>
  </section>
);

WorkByNumbersSection.propTypes = {
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  mainStats: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        stat: PropTypes.number.isRequired,
        heroImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}).isRequired,
          }).isRequired,
        }).isRequired,
        imageAlt: PropTypes.string.isRequired,
      }).isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  localStats: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        statistic: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  bridgingStatement: PropTypes.string.isRequired,
};

export default WorkByNumbersSection;
