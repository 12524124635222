import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import GoogleTagManager from '@src/components/utils/google-tag-manager/google-tag-manager';
import GoogleAnalytics from '@src/components/utils/google-analytics/google-analytics';
import HotJar from '@src/components/utils/hotjar/hotjar';
import './layout.scss';

const Layout = ({
  navigation,
  header,
  children,
  footer,
}) => (
  <>
    {navigation}
    {header}
    <main>
      {children}
    </main>
    {footer}
    <GoogleTagManager
      gtmId={R.pathOr(null, [], process.env.GATSBY_SUPPORT_OFFICE_GTM_ID)}
      siteHasCookieHub={!R.isEmpty(R.pathOr('', [], process.env.GATSBY_SUPPORT_OFFICE_COOKIE_HUB_ID))}
    />
    <GoogleAnalytics
      gaId={R.pathOr(null, [], process.env.GATSBY_SUPPORT_OFFICE_GA_ID)}
      siteHasCookieHub={!R.isEmpty(R.pathOr('', [], process.env.GATSBY_SUPPORT_OFFICE_COOKIE_HUB_ID))}
    />
    <HotJar
      hotJarId={R.pathOr(null, [], process.env.GATSBY_SUPPORT_OFFICE_HOTJAR_ID)}
      hotJarSv={R.pathOr(null, [], process.env.GATSBY_SUPPORT_OFFICE_HOTJAR_SV)}
      siteHasCookieHub={!R.isEmpty(R.pathOr('', [], process.env.GATSBY_SUPPORT_OFFICE_COOKIE_HUB_ID))}
    />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navigation: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
};

export default Layout;
