import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Rellax from 'rellax';
import * as R from 'ramda';
import Container from '@src/components/ui/container/container';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import './our-commitment.scss';

const OurCommitmentSection = ({
  heading,
  content,
}) => {
  useEffect(() => {
    if (!R.isNil(R.pathOr(null, ['parallaxApplied'], window))) {
      window.parallaxApplied.destroy();
    }
    window.parallaxApplied = new Rellax('.rellax', {
      center: true,
    });
  });

  return (
    <section id="our-commitment" className="OurCommitment">
      <Container>
        <header>
          <ChrMarkdown>
            { heading }
          </ChrMarkdown>
        </header>
        <div>
          <ChrMarkdown>
            { content }
          </ChrMarkdown>
        </div>
      </Container>
      <div className="OurCommitment__features">
        <figure className="OurCommitment__feature-item OurCommitment__feature-item--one rellax" data-rellax-speed="1" />
        <figure className="OurCommitment__feature-item OurCommitment__feature-item--two rellax" data-rellax-speed="2" />
        <figure className="OurCommitment__feature-item OurCommitment__feature-item--three rellax" data-rellax-speed="0" />
        <figure className="OurCommitment__feature-item OurCommitment__feature-item--four rellax" data-rellax-speed="1" />
        <figure className="OurCommitment__feature-item OurCommitment__feature-item--five rellax" data-rellax-speed="2" />
        <figure className="OurCommitment__feature-item OurCommitment__feature-item--six rellax" data-rellax-speed="0" />
      </div>
    </section>
  );
};

OurCommitmentSection.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default OurCommitmentSection;
