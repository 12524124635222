import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useCountUp } from 'react-countup';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import './main-stat.scss';

require('intersection-observer');

const MainStat = ({
  id,
  sequence,
  stat,
  heroImage,
  imageAlt,
  description,
}) => {
  const { countUp, start } = useCountUp({
    start: 0,
    end: stat,
    separator: ',',
  });

  useEffect(() => {
    const odometerObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          start();
          odometerObserver.unobserve(entry.target);
        }
      });
    }, {
      rootMargin: '0px',
      threshold: 1,
    });
    odometerObserver.observe(document.getElementById(`count-up__${id}`));
  }, []);

  return (
    <div className={`MainStat MainStat--${sequence}`}>
      <Img fluid={heroImage} alt={imageAlt} />
      <h3 id={`count-up__${id}`}>{countUp}</h3>
      <ChrMarkdown>
        { description }
      </ChrMarkdown>
    </div>
  );
};

MainStat.propTypes = {
  id: PropTypes.string.isRequired,
  sequence: PropTypes.number.isRequired,
  stat: PropTypes.number.isRequired,
  heroImage: PropTypes.shape({}).isRequired,
  imageAlt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default MainStat;
