import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useStaticQuery, graphql } from 'gatsby';
import DarkLogo from '@src/images/chr-logo-dark.svg';
import LightLogo from '@src/images/chr-logo-light.svg';

const ChrLogo = ({ useDarkVersion, classNames }) => {
  const queries = useStaticQuery(graphql`
    {
      common: markdownRemark(fileAbsolutePath: {regex: "/common.md/"}) {
        frontmatter {
          worldVision
          childhoodRescue
        }
      }
    }`);

  if (!useDarkVersion) {
    return (
      <img className={`site-logo${!R.isNil(classNames) ? ` ${classNames}` : ''}`} src={DarkLogo} alt={`${queries.common.frontmatter.worldVision} ${queries.common.frontmatter.childhoodRescue}`} />
    );
  }
  return (
    <img className={`site-logo${!R.isNil(classNames) ? ` ${classNames}` : ''}`} src={LightLogo} alt={`${queries.common.frontmatter.worldVision} ${queries.common.frontmatter.childhoodRescue}`} />
  );
};

ChrLogo.propTypes = {
  useDarkVersion: PropTypes.bool,
  classNames: PropTypes.string,
};

ChrLogo.defaultProps = {
  useDarkVersion: false,
  classNames: null,
};

export default ChrLogo;
