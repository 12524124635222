import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import * as R from 'ramda';

const GoogleAnalytics = ({ gaId, siteHasCookieHub }) => {
  if (R.isNil(gaId)
    || R.isEmpty(gaId)) {
    return (<></>);
  }

  const renderCookieHubGa = () => (
    `
      function injectGA() {
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        if (typeof ga === "function") {
          ga('create', '${gaId}', 'auto', {});
          ga('set', 'anonymizeIp', true);
        }
      }
      if (false) { 
        injectGA();
      } else {
        document.addEventListener('cookie_consent', function (e) {
          injectGA();
        }, false);
      }      
      document.addEventListener('cookiehub', function (e) {
        if (e.detail.allowed && e.detail.type === 'analytics') {
          injectGA();
        }
      });  
    `
  );
  const renderStandardGa = () => (
    `
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    if (typeof ga === "function") {
      ga('create', '${gaId}', 'auto', {});
      ga('set', 'anonymizeIp', true);
    }
    `
  );

  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML: (siteHasCookieHub
            ? renderCookieHubGa()
            : renderStandardGa()),
        },
      ]}
    />
  );
};

GoogleAnalytics.propTypes = {
  gaId: PropTypes.string,
  siteHasCookieHub: PropTypes.bool,
};

GoogleAnalytics.defaultProps = {
  gaId: null,
  siteHasCookieHub: false,
};

export default GoogleAnalytics;
