import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import './hamburger-button.scss';

const HamburgerButton = ({ isActive, onPress, style }) => {
  const queries = useStaticQuery(graphql`
    {
      common: markdownRemark(fileAbsolutePath: {regex: "/common.md/"}) {
        frontmatter {
          open
          close
        }
      }
    }`);

  return (
    <button aria-label={`${isActive ? queries.common.frontmatter.close : queries.common.frontmatter.open}`} onClick={(e) => onPress(e)} type="button" className={`u-md-hidden hamburger-button ${style} ${isActive ? ' opened' : ''}`} aria-expanded={isActive ? 'true' : 'false'}>
      <span className="screen-reader-text">Menu</span>
      <svg className="icon icon-menu-toggle" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100">
        <g className="svg-menu-toggle">
          <path className="line line-1" d="M5 13h90v13H5z" />
          <path className="line line-2" d="M5 43h90v13H5z" />
          <path className="line line-3" d="M5 73h90v13H5z" />
        </g>
      </svg>
    </button>
  );
};

HamburgerButton.propTypes = {
  isActive: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.string,
};

HamburgerButton.defaultProps = {
  isActive: false,
  style: 'dark',
};

export default HamburgerButton;
