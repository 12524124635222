import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import HtmlParser from 'react-markdown/plugins/html-parser';

const ChrMarkdown = ({ children }) => {
  // See https://github.com/aknuds1/html-to-react#with-custom-processing-instructions
  // for more info on the processing instructions
  const parseHtml = HtmlParser({
    isValidNode: (node) => node.type === 'break' || node.name === 'small',
  });
  return (
    <ReactMarkdown
      escapeHtml={false}
      astPlugins={[parseHtml]}
    >
      {children}
    </ReactMarkdown>
  );
};

ChrMarkdown.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChrMarkdown;
