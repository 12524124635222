/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useStaticQuery, graphql } from 'gatsby';
import HamburgerButton from '@src/components/ui/hamburger-button/hamburger-button';
import ChrLogo from '@src/components/ui/chr-logo/chr-logo';
import Modal from '@src/components/ui/modal/modal';
import MenuItem from './menu-item';
import './page-nav.scss';

const PageNav = ({
  pageSections,
}) => {
  const queries = useStaticQuery(graphql`
    {
      common: markdownRemark(fileAbsolutePath: {regex: "/common.md/"}) {
        frontmatter {
          worldVision
          childhoodRescue
          mainNav
          backToTop
        }
      }
    }`);

  const [showMenu, setShowMenu] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const [activeItem, setActiveItem] = useState('');

  let prevScrollPos = 0;
  const [scrollingDown, setScrollingDown] = useState(false);

  const pageSectionCoordinates = R.fromPairs(
    R.map(
      (section) => ([[section.id], null]),
      pageSections,
    ),
  );

  const handleScroll = () => {
    const curPos = window.scrollY;
    const pageNav = document.getElementById('page-nav');
    let curSection = null;

    setAtTop(curPos <= 100); // set at top if less than or equal to 0px from top

    for (const section in pageSectionCoordinates) {
      curSection = curPos >= pageSectionCoordinates[section] ? section : curSection;
      if (curSection !== section && curSection !== null) {
        break;
      }
    }
    if (curSection !== activeItem && curSection !== null) {
      setActiveItem(curSection);
    }

    // show/hide nav if scrolling up or down and the menu is not active on mobile
    if (curPos > 400 && pageNav.className.indexOf('menu-active') === -1) {
      setScrollingDown(prevScrollPos < curPos);
      prevScrollPos = curPos;
    }
  };

  const getAnchorPoints = () => {
    const curScroll = window.scrollY - 100;
    for (const section in pageSectionCoordinates) {
      try {
        pageSectionCoordinates[section] = document.getElementById(section).getBoundingClientRect().top + curScroll;
      } catch (e) {
        console.error(e);
      }
    }
    handleScroll();
  };

  useEffect(() => {
    // const observer = new MutationObserver(getAnchorPoints);
    // observer.observe(document.getElementById('___gatsby'), {
    //   childList: true,
    //   subtree: true,
    // });
    getAnchorPoints();
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav id="page-nav" aria-label={queries.common.frontmatter.mainNav} className={`page-nav${showMenu ? ' menu-active' : ''}${scrollingDown ? ' scrolling-down' : ''}${atTop ? '' : ' scrolled'}`}>
      <div className="page-nav__container">
        <button
          type="button"
          onClick={
            (ev) => {
              ev.stopPropagation();
              document.getElementById('PageHeader').scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }
          className="site-logo"
          aria-label={queries.common.frontmatter.backToTop}
        >
          <ChrLogo useDarkVersion={!showMenu && !atTop} />
        </button>

        <div className="menu-actions">
          <div className={`DesktopMenu ${!atTop && 'DesktopMenu--scrolled'}`}>
            <ul className="DesktopMenu__list">
              {R.map((section) => (
                <MenuItem
                  id={section.id}
                  label={section.label}
                  key={`menuitem_${section.id}`}
                  active={section.id === activeItem}
                  classNames="DesktopMenu__item"
                />
              ), pageSections)}
            </ul>
          </div>

          <HamburgerButton
            isActive={showMenu}
            style={!showMenu && !atTop ? 'light' : 'dark'}
            onPress={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}
          />
        </div>
      </div>
      <Modal classNames="primary-nav-modal" onClose={() => setShowMenu(false)} show={showMenu}>
        <ul className="menu-items">
          {R.map((section) => (
            <MenuItem
              id={section.id}
              label={section.label}
              key={`menuitem_${section.id}`}
              active={section.id === activeItem}
              afterClick={() => { setShowMenu(false); }}
            />
          ), pageSections)}
        </ul>
      </Modal>
    </nav>
  );
};

PageNav.propTypes = {
  pageSections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

export default PageNav;
