import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon,
} from 'react-share';
import './share-icons.scss';

const ShareIcons = ({
  shareTitle, shareEmailMessage, disableEmailShare,
}) => {
  const siteUrl = R.pathOr('', [], process.env.GATSBY_SITE_URL);
  const twitterHandle = R.pathOr('', [], process.env.GATSBY_TWITTER_HANDLE);

  return (
    <div className="share-icons">
      <FacebookShareButton url={siteUrl} quote={shareTitle} className="mn_share-button">
        <FacebookIcon size={48} round className="mn_share-icon" iconFillColor="#333333" bgStyle={{ fill: '#EFF2F6' }} />
      </FacebookShareButton>
      <TwitterShareButton url={siteUrl} title={shareTitle} via={twitterHandle} className="mn_share-button">
        <TwitterIcon size={48} round className="mn_share-icon" iconFillColor="#333333" bgStyle={{ fill: '#EFF2F6' }} />
      </TwitterShareButton>
      { !disableEmailShare && (
        <EmailShareButton url={siteUrl} subject={shareTitle} body={shareEmailMessage} separator="  --  " className="mn_share-button">
          <EmailIcon size={48} round className="mn_share-icon" iconFillColor="#333333" bgStyle={{ fill: '#EFF2F6' }} />
        </EmailShareButton>
      )}
    </div>
  );
};

ShareIcons.propTypes = {
  shareTitle: PropTypes.string.isRequired,
  shareEmailMessage: PropTypes.string,
  disableEmailShare: PropTypes.bool,
};

ShareIcons.defaultProps = {
  shareEmailMessage: '',
  disableEmailShare: false,
};

export default ShareIcons;
