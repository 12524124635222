import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import Video from './video';
import './on-the-ground.scss';

const OnTheGroundSection = ({
  heading,
  introduction,
  videos,
}) => (
  <section id="on-the-ground" className="OnTheGround">
    <header>
      <ChrMarkdown>
        { heading }
      </ChrMarkdown>
      <ChrMarkdown>
        { introduction }
      </ChrMarkdown>
    </header>

    <div className="OnTheGround__videos">
      { R.map((video) => (
        <Video
          key={`video--${video.frontmatter.id}`}
          id={`video--${video.frontmatter.id}`}
          heroImage={video.frontmatter.heroImage.childImageSharp.fluid}
          imageAlt={video.frontmatter.imageAlt}
          title={video.frontmatter.title}
          excerpt={video.frontmatter.excerpt}
          videoUrl={video.frontmatter.videoUrl}
        />
      ), videos)}
    </div>
  </section>
);

OnTheGroundSection.propTypes = {
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        excerpt: PropTypes.string.isRequired,
        heroImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}).isRequired,
          }).isRequired,
        }).isRequired,
        imageAlt: PropTypes.string.isRequired,
        videoUrl: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export default OnTheGroundSection;
