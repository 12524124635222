import PropTypes from 'prop-types';
import React from 'react';
import Container from '@src/components/ui/container/container';
import ChrMarkdown from '@src/components/ui/chr-markdown/chr-markdown';
import ChrLogo from '@src/components/ui/chr-logo/chr-logo';
import SocialIcons from '@src/components/ui/social-icons/social-icons';
import './page-footer.scss';

const PageFooter = ({
  address,
  phone,
  copyright,
}) => (
  <footer id="PageFooter" className="PageFooter">
    <Container classNames="PageFooter__container">
      <ChrLogo />
      <ChrMarkdown>
        { address }
      </ChrMarkdown>
      <ChrMarkdown>
        { phone }
      </ChrMarkdown>
      <SocialIcons />
      <div className="copyright">
        <ChrMarkdown>
          { copyright }
        </ChrMarkdown>
      </div>
    </Container>
  </footer>
);

PageFooter.propTypes = {
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
};

export default PageFooter;
