import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import * as R from 'ramda';
import './feature-image.scss';

const FeatureImageSection = ({
  id,
  url,
  alt,
  caption,
  photographer,
}) => (
  <section id={id} className="FeatureImage">
    <Img fluid={url} alt={alt} />
    { !R.isNil(caption) && !R.isNil(photographer) && (
      <div className="caption">
        <p>{caption}</p>
        <p>{photographer}</p>
      </div>
    )}
  </section>
);

FeatureImageSection.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.shape({}).isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string,
  photographer: PropTypes.string,
};

FeatureImageSection.defaultProps = {
  caption: null,
  photographer: null,
};

export default FeatureImageSection;
